import React, {
    createRef,
    useRef,
    useState,
    useEffect,
    useContext,
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button } from '@saatva-bits/pattern-library.components.button'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { OFFSETS } from '@saatva-bits/pattern-library.utils.position'
import { useProductData, useProductState } from '@saatva-bits/pattern-library.modules.selection'

import useDeviceType from '@/hooks/useDeviceType'
import Reviews from '@/contexts/reviews'
import { CRIB_MATTRESS } from '@/constants/product-codes'
import { scrollToSection } from './stickyNavUtils'
import styles from './ProductStickyNav.module.scss'

const ProductStickyNav = ({
    links,
    currentId,
    productCode,
    ctaColorModifier = 'blue'
}) => {
    const { isMobile, isDesktop, deviceType } = useDeviceType('mobile')
    const linksRefArray = links.map(() => createRef())
    const linkRefs = useRef(linksRefArray)
    const [isSticky, setIsSticky] = useState(false)
    const [isMobileSticky, setIsMobileSticky] = useState(false)
    const [reviewOffsetAdjustment, setReviewOffsetAdjustment] = useState(150)
    const productData = useProductData(productCode)
    const productState = useProductState(productCode)
    const { getDiscounts } = useGetDiscounts()
    const name = productCode === CRIB_MATTRESS ? productData.name : productData.name.replace(' Mattress', '')
    const ReviewsContext = useContext(Reviews.Context)

    const selectedProductSkus = [{
        sku: productState.sku,
        parentSku: productData.sku,
        quantity: productState.quantity,
        category: productData.category,
        price: productState.price,
        isPrimaryProduct: productData.sku === productCode
    }]

    const {
        fullPrice,
        finalPrice
    } = getDiscounts(selectedProductSkus)

    const showStrikethroughPrice = fullPrice !== finalPrice

    const handleScroll = (contentAnchorTop) => {
        if (window.scrollY >= (contentAnchorTop)) {
            setIsSticky(true)
        } else {
            setIsSticky(false)
        }
    }

    const handleMobileScroll = (contentAnchorTop) => {
        if (window.scrollY >= (contentAnchorTop)) {
            setIsMobileSticky(true)
        } else {
            setIsMobileSticky(false)
        }
    }

    useEffect(() => {
        // Set constant offset for nav stickiness based on the buystack/content wrapper and initial window position.
        // setIsSticky handles the nav stickiness while setIsMobileSticky handles the mobile CTA at the bottom of the page.
        const anchorElement = !isDesktop ? document.querySelector('#productPanel') : document.querySelector('.js-contentNav-anchor')
        const anchorElementTop = Math.abs(window.scrollY + anchorElement.getBoundingClientRect().top)
        const mobileAnchorElementTop = anchorElementTop * 12

        document.addEventListener('scroll', () => handleScroll(anchorElementTop))
        document.addEventListener('scroll', () => handleMobileScroll(mobileAnchorElementTop))

        if (window.scrollY >= anchorElementTop) {
            setIsSticky(true)
        } else {
            setIsSticky(false)
        }

        if (window.scrollY >= (mobileAnchorElementTop)) {
            setIsMobileSticky(true)
        } else {
            setIsMobileSticky(false)
        }

        return () => {
            document.removeEventListener('scroll', () => handleScroll(anchorElementTop))
            document.removeEventListener('scroll', () => handleScroll(mobileAnchorElementTop))
        }
    }, [isMobile, isDesktop])

    const navClasses = classNames(styles.nav, {
        [styles.navSticky]: isSticky
    })

    const mobileNavClasses = classNames(styles.lowerNav, 'u-hidden--lg-up', {
        [styles.lowerNavSticky]: isMobileSticky
    })

    const linksCount = links.filter(link => !link.isCta).length
    const linkSetClasses = classNames(styles.linkSet, {
        [styles.linkSetSpaceAdjusted]: linksCount <= 4
    })

    const strikethroughPriceClasses = classNames(styles.strikethroughPrice, 'u-marginRight--xs')

    const renderPrice = (className) => {
        return (
            <div className={`${styles.priceContainer} ${className}`}>
                {showStrikethroughPrice &&
                    <span className={strikethroughPriceClasses}>{formatCurrency(fullPrice)}</span>
                }
                <span key={finalPrice}>{formatCurrency(finalPrice)}</span>
            </div>
        )
    }

    const renderCTA = (priceClass, ctaClass, colorModifier) => {
        const buttonClasses = classNames(
            styles.shopNowBtn,
            styles.fadeInAndUpAnimation,
            styles.shopNowBtnFadeIn,
            ctaClass, {
                [styles[`${colorModifier}`]]: colorModifier,
            },
        )
        return <>
            <div className={priceClass}>
                {renderPrice('u-hidden--sm-down')}
                <Button
                    className={buttonClasses}
                    kind='primary'
                    onClick={() => scrollToSection('productPanel', OFFSETS.both[deviceType])}>
                    <span className={styles.linkName}>Shop Now</span>
                </Button>
            </div>
        </>
    }

    const renderLinks = () => {
        let ctaElement = null
        return (
            <>
                <nav className={styles.linkWrapper}>
                    <ul>
                        {links.map((link, i) => {
                            const linkId = link.id === 'reviews' ? 'customer-reviews' : link.id
                            const classes = classNames(
                                styles.link,
                                {
                                    [styles.active]: link.id === currentId,
                                    [styles.linkSpaceAdjusted]: linksCount <= 4
                                }, link.customClass)
                            if (link.isCta) {
                                ctaElement = renderCTA(
                                    `${styles.productInfoDetails} u-hidden--lg-down`,
                                    'u-hidden--lg-down',
                                    link.colorModifier
                                )
                            } else {
                                return (
                                    <li key={`link${i}`}>
                                        <button
                                            type="button"
                                            className={classes}
                                            ref={linkRefs.current[i]}
                                            onClick={() => {
                                                let offset = OFFSETS.both[deviceType]
                                                if (!isDesktop && linkId === 'customer-reviews') {
                                                    // Toggles the mobile review accordion open
                                                    ReviewsContext.dispatch({
                                                        type: 'SET_ACCORDION_VALUES',
                                                        accordionName: 'reviews-accordion',
                                                        accordionContent: 'reviews-accordion-content',
                                                        accordionTitle: 'reviews-accordion-title',
                                                    })
                                                    // Add extra height to account for unexpanded accordion on first scroll
                                                    offset -= reviewOffsetAdjustment
                                                    setReviewOffsetAdjustment(0) // reset to avoid extra space on subsequent scroll
                                                }
                                                scrollToSection(linkId, offset)
                                            }}>
                                            <span className={styles.linkName}>{link.name}</span>
                                        </button>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </nav>
                {ctaElement}
            </>
        )
    }

    return (
        <>
            <div className={navClasses}>
                <div className='container'>
                    <div className='row'>
                        <div className={linkSetClasses}>
                            <div className={`${styles.productInfo} u-hidden--lg-down`}>
                                <div className={styles.productInfoLabel}>{name}</div>
                            </div>
                            {renderLinks()}
                        </div>
                    </div>
                </div>
            </div>
            <div className={mobileNavClasses}>
                <div className={`${styles.productInfo} u-hidden--lg-up`}>
                    <div className={`${styles.productInfoText}`}>
                        <div className={styles.productInfoLabel}>{name}</div>
                        {renderPrice('u-hidden--sm-up')}
                    </div>
                </div>
                {renderCTA(styles.productInfoDetails, 'u-hidden--lg-up', ctaColorModifier)}
            </div>
        </>
    )
}

ProductStickyNav.propTypes = {
    links: PropTypes.array,
    currentId: PropTypes.string,
    productCode: PropTypes.string.isRequired,
    supportValuePropsBgColor: PropTypes.string
}

export default ProductStickyNav
